<template>
    <div class="feedback-top">
        <div class="badge m-b-12">피드백하기</div>
        <h1 class="title m-b-8" v-html="title" @click="onClick" />
        <p v-if="description" class="desc m-b-40" v-html="description" />
    </div>
</template>

<script>
export default {
    name: 'FeedbackTopComponent',
    props: {
        title: {
            type: String,
            required: true,
        },
        description: String,
    },
    methods: {
        onClick() {
            this.$emit('seeProfile')
        },
    },
}
</script>

<style lang="scss" scoped>
.feedback-top {
    .badge {
        height: 24px;
        width: 80px;
        font-size: 13px;
        color: $grey-09;
        background-color: $grey-02;

        @include f-medium;
    }

    ::v-deep p {
        display: flex;
        align-items: center;
    }

    .title {
        color: black;
        font-size: 24px;
        line-height: normal;
        font-weight: 500;
        letter-spacing: -0.4px;

        @include f-medium;
    }

    ::v-deep .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }

    .desc {
        font-size: 14px;
        font-weight: 500;
        color: $purple-primary;

        @include f-medium;
    }
}
</style>
