<template>
    <ul class="feedback-option-items" v-if="items.length > 0">
        <p v-if="multiSelect" class="multi-select m-b-8">*복수 선택 가능</p>
        <li
            class="item"
            :class="{ selected: item.selected }"
            v-for="(item, idx) in items"
            :key="item.id"
            @click="onClickItem(idx)"
        >
            <p v-html="item.title" />
        </li>
    </ul>
</template>

<script>
export default {
    name: 'FeedbackOptionItems',
    props: {
        value: Array,
        multiSelect: Boolean,
    },
    computed: {
        items() {
            return this.value
        },
    },
    methods: {
        onClickItem(idx) {
            if (!this.multiSelect) {
                this.items.forEach(item => {
                    item.selected = false
                })
                this.$set(this.items[idx], 'selected', !this.items[idx].selected)
                setTimeout(() => this.$emit('select', idx), 500) // select 이벤트는 단일 선택인 경우에만 emit
            } else {
                this.$set(this.items[idx], 'selected', !this.items[idx].selected)
            }

            this.$emit('input', this.items)
        },
    },
}
</script>

<style lang="scss" scoped>
.feedback-option-items {
    margin-bottom: 24px;

    .multi-select {
        font-size: 12px;
        color: $grey-07;
    }

    .item {
        padding: 11px 16px;
        border-radius: 8px;
        border: solid 1px $grey-02;
        font-size: 15px;
        color: $grey-08;
        min-height: 44px;

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        &.selected {
            border-color: $purple-primary;
            color: black;
        }
    }
}
</style>
